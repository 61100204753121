// Theme Colors
$primary-color: #0192ed;
$white: #ffffff;
$black: #000000;
$text-color: #132028;
$sub-text: #44464a;
$light-text: #828384;
$footer-text: #acacac;
$light-green: #e0f8de;
$light-pink: #f9b9bb;
$light-orange: #f8a02d;
$slider-text: #5a6369;
$border-color: #eeeeee;
$gradient-dark: #252525;
$service-bg: #f6f6f6;
$service-text: #dddddd;
$why-us-text: #6b6b6b;
$sidebar-text: #9d9d9d;
$mini-banner: #cdd4d6;

// Common Style
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Satoshi-Variable";
  background-color: $black;
  overflow-y: auto;
  overflow-x: hidden;
}

::selection {
  background-color: $primary-color;
  color: $white;
}

a {
  text-decoration: none;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
  li {
    list-style: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.section-py {
  padding-block: 100px;
}

.light-bg {
  background-color: $white;
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  border-radius: 10px;
  padding: 14px 50px;
  font-size: 17px;
  font-weight: 700;
  color: $white;
  transition: all 0.4s;
  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $white;
    transform: scale(0.97);
  }
}

.btn-outline {
  background-color: $black;
  border: 1px solid rgba($white, 0.5);
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 17px;
  font-weight: 500;
  color: $white;
  position: relative;
  overflow: hidden;
  transition: all 0.4s;
  &::after {
    content: "";
    background: rgba($white, 0.2);
    width: 50px;
    height: 155px;
    position: absolute;
    left: -75px;
    top: -50px;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: rotate(35deg);
  }
  &:hover {
    border: 1px solid rgba($white, 0.5);
    color: $white;
    background-color: $black;
    &::after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

// Header
.header-wrapper {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  transition: all 0.4s;
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 20px;
    .left-header {
      .toggle-bar {
        cursor: pointer;
        width: 35px;
        display: none;
      }
    }
    .right-header {
      display: flex;
      align-items: center;
      ul {
        display: flex;
        padding-right: 50px;
        li {
          padding-right: 80px;
          &:last-child {
            padding-right: 0;
          }
          a {
            color: $white;
            font-size: 18px;
            font-weight: 500;
            &.active {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  &.header-sticky {
    position: sticky;
    background-color: $black;
    .header-content {
      padding-block: 12px;
    }
  }
  &.header-unset {
    position: unset;
  }
}

// Home Slider
.home-slider {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, $black -20.75%, rgba($black, 0) 63.52%);
    width: 100%;
    height: 170px;
    z-index: 1;
  }
  .slider-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .slider-content {
      padding-block: 220px;
      position: relative;
      h1 {
        color: $white;
        font-size: 52px;
        font-weight: 900;
        width: 35%;
      }
      p {
        color: $white;
        font-size: 20px;
        font-weight: 300;
        width: 52%;
        margin-block: 28px;
      }
      .slider-pagination {
        position: absolute;
        right: 50px;
        bottom: 75px;
        h5 {
          color: $white;
          font-size: 30px;
          font-weight: 600;
          span {
            color: rgba($white, 0.2);
            font-size: 18px;
            margin-left: 7px;
            letter-spacing: 2px;
          }
        }
      }
    }
  }
  .slick-dots {
    bottom: 70px;
    li {
      width: 40px;
      &.slick-active {
        button {
          &::before {
            color: $white;
          }
        }
      }
      button {
        &::before {
          color: $white;
        }
        &::after {
          content: "";
          display: flex;
          width: 20px;
          height: 1px;
          background-color: rgba($white, 0.2);
          margin-left: 20px;
        }
      }
      &:last-child {
        width: 20px;
        button {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

// About Us
.about-us {
  .globe-container {
    background-image: url(../../../public/images/png/globe-layer.webp);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 84%;
    #threeGlobe {
      max-width: 100%;
      height: 700px;
      margin: -145px 0 -28px 0;
      cursor: grab;
      display: flex;
      justify-content: center;
    }
    .client-slider {
      display: flex;
      align-items: center;
      gap: 20px;
      overflow: hidden;
      .client-detail {
        display: flex;
        align-items: center;
        gap: 20px;
        animation: scroll-slider 30s linear infinite;
        img {
          width: 120px;
          border-radius: 10px;
          box-shadow: 0px 5px 74px 0px rgba($black, 0.1);
        }
      }
    }
  }
  h2 {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: $text-color;
    margin-bottom: 40px;
    position: relative;
    span {
      color: $primary-color;
      font-style: italic;
    }
  }
  p {
    color: $sub-text;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .about-content {
    gap: 20px;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    h5 {
      font-size: 36px;
      font-weight: 700;
      color: $text-color;
      margin-bottom: 10px;
      span {
        font-size: 36px;
        font-weight: 700;
        color: $text-color;
        margin-bottom: 10px;
      }
    }
    span {
      font-size: 16px;
      font-weight: 500;
      color: $sub-text;
    }
  }
  @keyframes scroll-slider {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

// Services
.our-services {
  h3 {
    color: $white;
    font-size: 60px;
    font-weight: 900;
    width: 60%;
  }
  p:not(.mb-0) {
    color: $white;
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
  }
  ul {
    padding-left: 15px;
    margin-block: 22px;
    li {
      list-style: disc;
      color: $light-text;
      font-size: 20px;
    }
  }
  .services-video {
    position: relative;
    border: 1px solid transparent;
    background: linear-gradient(90deg, $black, rgba($white, 0.2) 50%) border-box;
    border-radius: 0 40px 40px 0;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      background: radial-gradient(
        ellipse at center,
        transparent 35%,
        rgba($black, 0.8) 57%,
        $black 75%
      );
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    video {
      object-fit: cover;
    }
    .play-btn {
      position: absolute;
      width: 75px;
      height: 75px;
      border-radius: 100%;
      border: 1px solid $white;
      background-color: rgba($black, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      svg {
        color: $white;
        font-size: 25px;
      }
    }
  }
}

// Why We
.why-we {
  .badge {
    background-color: $light-green;
    color: $sub-text;
    padding: 8px 14px;
    border-radius: 70px;
    font-size: 14px;
    font-weight: 500;
  }
  h2 {
    font-size: 36px;
    color: $text-color;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  p {
    color: $sub-text;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;
    border-bottom: 1px solid $border-color;
    padding-bottom: 22px;
    text-transform: capitalize;
  }
  .why-we-content {
    h6 {
      font-size: 16px;
      font-weight: 700;
      color: $text-color;
      margin-top: 14px;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: $sub-text;
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
      text-transform: unset;
    }
  }
}

// Client Review
.client-review {
  .client-img {
    position: relative;
    padding-block: 30px;
    img {
      filter: grayscale(1);
      transition: all 0.4s;
    }
    .img-effect {
      position: absolute;
      background-color: $light-pink;
      width: 420px;
      height: 500px;
      border-radius: 10px;
      top: 30px;
      left: 12px;
      rotate: 6deg;
      z-index: -1;
      transition: all 0.4s;
    }
    &:hover {
      img {
        filter: grayscale(0);
      }
      .img-effect {
        rotate: -6deg;
      }
    }
  }
  p {
    font-size: 28px;
    font-weight: 500;
    color: $white;
    margin-top: -85px;
    margin-bottom: 110px;
  }
  h6 {
    font-size: 22px;
    font-weight: 500;
    color: $white;
    margin-bottom: 5px;
  }
  span {
    font-size: 18px;
    font-weight: 400;
    color: $white;
  }
  .slick-arrow {
    top: 78%;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    z-index: 1;
    rotate: 50deg;
    &.slick-prev {
      left: -17px;
      border: 1px solid $white;
      &::before {
        content: "\f063";
        font-family: "Font Awesome 6 Free";
        font-weight: 600;
      }
    }
    &.slick-next {
      right: unset;
      left: 48px;
      background-color: $primary-color;
      &::before {
        content: "\f062";
        font-family: "Font Awesome 6 Free";
        font-weight: 600;
      }
    }
  }
}

// Blog
.blog-list {
  .btn-primary {
    padding: 14px 35px;
    margin-top: 42px;
    svg {
      font-size: 12px;
    }
  }
  .blog-card {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 1.66px 12.42px 0px rgba($black, 0.1);
    padding: 14px;
    &:hover {
      .blog-img {
        img {
          transform: scale(1.1);
        }
      }
    }
    .blog-img {
      overflow: hidden;
      img {
        width: 100%;
        transition: all 0.4s;
      }
    }
    span {
      color: $sub-text;
      font-weight: 500;
      font-size: 12px;
      margin-block: 14px;
    }
    h5 {
      font-size: 20px;
      font-weight: 700;
      color: $text-color;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: $sub-text;
      margin-top: 12px;
      margin-bottom: 25px;
    }
    a {
      font-size: 14px;
      font-weight: 500;
      color: $primary-color;
      svg {
        font-size: 10px;
      }
    }
  }
}

// Journey
.journey-animation {
  > div {
    min-width: calc(100% + 230px);
    svg {
      margin: -100px 0 -22px -65px;
      cursor: default;
    }
  }
}

// Mini Banner
.mini-banner {
  background-image: url(../../../public/images/png/mini-banner.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  h2 {
    font-size: 40px;
    font-weight: 700;
    color: $black;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: $black;
    margin-top: 35px;
    margin-bottom: 15px;
  }
  .btn-primary {
    padding: 12px 30px;
  }
  .slick-slider {
    .slick-list {
      min-height: 184px;
      .slick-track {
        .slick-slide {
          span {
            font-size: 36px;
            font-weight: 900;
            background: linear-gradient($white 20%, $slider-text 50%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
          &.slick-active {
            span {
              color: $primary-color;
              -webkit-text-fill-color: unset;
              font-size: 50px;
            }
            &.slick-current {
              span {
                background: linear-gradient(
                  to top,
                  $white 20%,
                  $slider-text 40%
                );
                -webkit-text-fill-color: transparent;
                background-clip: text;
                font-size: 36px;
              }
            }
          }
        }
      }
    }
  }
}

// Footer
.footer-wrapper {
  background-image: url(../../../public/images/png/footer-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 60px;
  h3 {
    font-size: 30px;
    font-weight: 700;
    color: $white;
    margin-bottom: 45px;
  }
  ul {
    li {
      display: flex;
      align-items: center;
      gap: 14px;
      font-size: 17px;
      font-weight: 400;
      color: $footer-text;
      margin-bottom: 28px;
      a {
        color: $footer-text;
      }
    }
  }
  .contact-info {
    display: flex;
    gap: 10px;
    margin-top: 65px;
    h4 {
      font-size: 25px;
      font-weight: 500;
      color: $white;
      flex-shrink: 0;
    }
    p {
      font-size: 17px;
      font-weight: 500;
      color: $footer-text;
      margin-bottom: 0;
      margin-top: 5px;
    }
    ul {
      margin-top: 5px;
      li {
        margin-bottom: 4px;
        font-weight: 500;
      }
    }
  }
  .sub-footer {
    margin-top: 60px;
    .social-icons {
      display: flex;
      align-items: center;
      gap: 27px;
    }
    span {
      font-size: 17px;
      font-weight: 400;
      color: $white;
    }
    p {
      font-size: 17px;
      font-weight: 300;
      color: $white;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}

// Services Slider
.service-slider {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, $black -20.75%, rgba($black, 0) 63.52%);
    width: 100%;
    height: 170px;
    z-index: 1;
  }
  .slider-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .slider-content {
      padding-block: 250px;
      position: relative;
      h1 {
        color: $white;
        font-size: 52px;
        font-weight: 900;
        width: 40%;
      }
      p {
        color: $white;
        font-size: 20px;
        font-weight: 300;
        width: 52%;
        margin-block: 28px;
      }
    }
  }
  .slick-dots {
    bottom: 70px;
    li {
      width: 40px;
      &.slick-active {
        button {
          &::before {
            color: $white;
          }
        }
      }
      button {
        &::before {
          color: $white;
        }
        &::after {
          content: "";
          display: flex;
          width: 20px;
          height: 1px;
          background-color: rgba($white, 0.2);
          margin-left: 20px;
        }
      }
      &:last-child {
        width: 20px;
        button {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

// Service Section
.service-section {
  .service-list {
    background-color: $service-bg;
    display: flex;
    align-items: center;
    gap: 42px;
    border-radius: 20px;
    padding: 48px 36px;
    margin-bottom: 27px;
    &:last-child {
      margin-bottom: 0;
    }
    h3 {
      color: $service-text;
      font-size: 220px;
      font-weight: 700;
      line-height: 0.8;
      width: 130px;
      flex-shrink: 0;
    }
    h4 {
      color: $primary-color;
      font-size: 36px;
      font-weight: 700;
    }
    p {
      color: $black;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 0;
      margin-top: 5px;
      line-height: 40px;
    }
  }
}

// Why Us
.why-us {
  .why-us-img {
    margin-left: 30px;
  }
  .why-us-list {
    background: linear-gradient(
      180deg,
      $gradient-dark -27.38%,
      transparent 100%
    );
    padding: 12px 20px;
    border-radius: 14px;
    margin-bottom: 34px;
    width: 97%;
    &:first-child {
      margin-left: -22%;
      animation-delay: 0.1s;
    }
    &:nth-child(2) {
      margin-left: -10%;
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      margin-left: -1%;
      animation-delay: 0.3s;
    }
    &:nth-child(5) {
      margin-left: -1%;
      animation-delay: 0.4s;
    }
    &:nth-child(6) {
      margin-left: -10%;
      animation-delay: 0.5s;
    }
    &:last-child {
      margin-left: -22%;
      margin-bottom: 0;
      animation-delay: 0.6s;
    }
    h5 {
      font-size: 22px;
      font-weight: 500;
      color: $white;
    }
    p {
      font-size: 17px;
      font-weight: 300;
      color: $why-us-text;
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
}

// Portfolio
.our-portfolio {
  .nav-tabs {
    border: 0;
    gap: 42px;
    .nav-item {
      .nav-link {
        border: 0;
        font-size: 17px;
        font-weight: 500;
        color: $sub-text;
        padding: 8px 37px;
        border-radius: 50px;
        &.active {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
  }
  .portfolio-tab {
    background-color: $text-color;
    padding: 18px;
    border-radius: 10px;
    margin-top: 30px;
    img {
      width: 100%;
    }
    .nav-tabs {
      border-bottom: 1px solid rgba($white, 0.1);
      padding-bottom: 25px;
      margin-bottom: 14px;
      padding-top: 70px;
      align-items: center;
      justify-content: space-between;
      .nav-item {
        .nav-link {
          font-size: 14px;
          color: $white;
          border: 1px solid $white;
          border-radius: 50px;
          padding: 5px 12px;
          &.active {
            font-size: 16px;
            border: 0;
            outline: 4px solid $primary-color;
            outline-offset: -1px;
          }
        }
      }
    }
    .tab-content {
      h3 {
        color: $white;
        font-size: 32px;
        font-weight: 700;
        position: absolute;
        top: 10px;
        width: 100%;
        text-align: center;
      }
      p {
        color: $white;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}

// Technologies
.technologies-section {
  .technologies-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    .technologies-name {
      h5 {
        color: $primary-color;
        font-weight: 700;
        font-size: 18px;
        display: none;
      }
      .technologies-img {
        width: 165px;
        height: 165px;
        border-radius: 100%;
        background: linear-gradient(
          180deg,
          $gradient-dark -27.38%,
          transparent 100%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          border: 1px solid transparent;
          background: linear-gradient(to top, $black 0%, $gradient-dark 100%)
            border-box;
          mask: linear-gradient($white 0 0) padding-box,
            linear-gradient($white 0 0);
          mask-composite: exclude;
        }
      }
    }
  }
}

// Process
.process-section {
  .process-steps {
    background-image: url(../../../public/images/png/process-bg.webp);
    background-repeat: no-repeat;
    width: 1150px;
    height: auto;
    margin-top: 15px;
    h4 {
      color: $primary-color;
      font-size: 36px;
      font-weight: 700;
    }
    p {
      color: $white;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 0;
      margin-top: 5px;
      line-height: 40px;
    }
    h3 {
      color: $white;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      padding-top: 10%;
    }
    .step-one {
      width: 97%;
      padding-left: 31%;
      margin-top: -15px;
    }
    .step-two {
      width: 76%;
      padding-left: 9%;
      padding-top: 12%;
    }
    .step-three {
      width: 100%;
      padding-left: 43%;
      padding-top: 12%;
    }
    .step-four {
      width: 74%;
      padding-left: 9%;
      padding-top: 12%;
    }
    .step-five {
      width: 98%;
      padding-left: 40%;
      padding-top: 12%;
    }
    .step-six {
      width: 78%;
      padding-left: 9%;
      padding-top: 12%;
    }
  }
}

// About Vasundhara
.about-vasundhara {
  background-image: url(../../../public/images/png/vasundhara-bg.webp);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, $black -20.75%, rgba($black, 0) 63.52%);
    width: 100%;
    height: 170px;
    z-index: 1;
  }
  .vasundhara-content {
    padding-block: 267px;
    h1 {
      font-size: 60px;
      font-weight: 900;
      color: $white;
      width: 30%;
      span {
        color: $primary-color;
      }
    }
    p {
      font-size: 20px;
      font-weight: 300;
      color: $white;
      width: 45%;
      margin-bottom: 18px;
      margin-top: 14px;
    }
  }
}

// Company Journey
.company-journey {
  background-image: url(../../../public/images/png/journey-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  padding-bottom: calc(25% + 10px);
  .journey-details {
    display: flex;
    align-items: center;
    gap: 200px;
    padding-top: 100%;
    &:nth-child(odd) {
      justify-content: flex-end;
    }
    &:first-child {
      padding-top: 25%;
    }
    &:nth-child(3) {
      padding-top: 28%;
    }
    &:nth-child(4) {
      padding-top: 18%;
    }
    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(6),
    &:last-child {
      padding-top: 24%;
    }
    .journey-year {
      background: linear-gradient(
        rgba($primary-color, 0.4) -27.38%,
        transparent 100%
      );
      border-radius: 10px;
      color: $primary-color;
      font-size: 52px;
      font-weight: 700;
      padding: 6px 44px;
    }
    .journey-content {
      background: linear-gradient(
        180deg,
        $gradient-dark -27.38%,
        transparent 100%
      );
      border-radius: 10px;
      color: $white;
      font-size: 30px;
      font-weight: 400;
      padding: 30px;
      width: 41%;
      text-align: center;
    }
  }
}

// Contact
.contact-section {
  background-color: $white;
  .contact-form {
    background-color: $white;
    margin-left: -120px;
    width: 620px;
    padding: 27px 45px;
    h2 {
      font-size: 32px;
      font-weight: 700;
      color: $text-color;
      text-shadow: 0px 2px 0px $primary-color;
      text-align: center;
      margin-bottom: 37px;
    }
    p {
      color: $sub-text;
      font-size: 15px;
      font-weight: 400;
      margin-top: 5px;
      margin-bottom: 25px;
      a {
        color: $primary-color;
      }
    }
    .btn-primary {
      border-radius: 50px;
      padding: 12px 25px;
      svg {
        rotate: 45deg;
      }
    }
    .form-control,
    .form-select {
      border-color: $border-color;
      padding: 10px 12px;
      margin-bottom: 18px;
      color: $sub-text;
      transition: all 0.4s;
      &:focus {
        border-color: $primary-color;
        box-shadow: 0 2px 10px 5px rgba($primary-color, 0.07);
      }
      &::placeholder {
        color: $sub-text;
      }
    }
    .form-select {
      background-image: url(../../../public/images/svg/select-arrow.svg);
      background-size: unset;
    }
  }
}

// Section Heading
.section-heading {
  margin: 0 auto 60px;
  text-transform: capitalize;
  h2 {
    font-size: 46px;
    font-weight: 700;
    text-shadow: 0px 2px 0px $primary-color;
  }
  p {
    color: $white;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
    margin-top: 40px;
  }
}

// Back To Top Button
.top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: $primary-color;
  color: $white;
  box-shadow: 0 1px 20px 1px $primary-color;
}

// Custom Cursor
.custom-cursor {
  width: 40px;
  height: 40px;
  border: 1px solid $primary-color;
  border-radius: 100%;
  position: fixed;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  animation: cursor-animation 0.5s infinite alternate;
  pointer-events: none;
  z-index: 9;
  box-shadow: 0 1px 4px 8px rgba($primary-color, 0.1);
  &::after {
    content: "";
    width: 5px;
    height: 5px;
    background-color: $primary-color;
    position: absolute;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes cursor-animation {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.8);
    }
  }
}

// Mobile Sidebar
.mobile-sidebar {
  &.offcanvas {
    background-color: $black;
    border-right: 1px solid $gradient-dark;
    .offcanvas-header {
      padding: 40px;
      .btn-close {
        filter: invert(1);
        box-shadow: none;
      }
    }
    .offcanvas-body {
      padding: 0;
      ul {
        li {
          a {
            display: flex;
            align-items: center;
            gap: 25px;
            color: $sidebar-text;
            font-size: 20px;
            font-weight: 400;
            padding: 20px 40px;
            &.active {
              background: linear-gradient(
                90deg,
                transparent 0.01%,
                rgba($primary-color, 0.3) 70.5%,
                rgba($primary-color, 0.5) 99.99%
              );
              color: $primary-color;
              svg {
                fill: $primary-color;
                stroke: none;
              }
            }
            svg {
              width: 30px;
              height: 30px;
              stroke: $sidebar-text;
              fill: none;
            }
          }
        }
      }
    }
  }
}

// Responsive
@media (max-width: 1600px) {
  .company-journey {
    .journey-details {
      &:first-child {
        padding-top: 20%;
      }
    }
  }
}
@media (max-width: 1399px) {
  .journey-animation {
    > div {
      min-width: calc(100% + 200px);
      svg {
        margin: -90px 0 -22px -57px;
      }
    }
  }
  .contact-section {
    .contact-form {
      width: 600px;
      h2 {
        font-size: 30px;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 12px;
      }
      .form-control,
      .form-select {
        margin-bottom: 12px;
      }
    }
  }
  .company-journey {
    .journey-details {
      &:first-child {
        padding-top: 15%;
      }
      .journey-year {
        font-size: 45px;
      }
      .journey-content {
        font-size: 25px;
        width: 40%;
      }
    }
  }
  .about-vasundhara {
    .vasundhara-content {
      h1 {
        font-size: 55px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .home-slider {
    .slider-img {
      .slider-content {
        h1 {
          font-size: 48px;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
  .service-slider {
    .slider-img {
      .slider-content {
        h1 {
          font-size: 48px;
        }
        p {
          font-size: 18px;
          width: 55%;
        }
      }
    }
  }
  .about-us {
    .globe-container {
      #threeGlobe {
        margin: -145px 0 -28px 0;
      }
    }
    .about-content {
      h5 {
        font-size: 32px;
        span {
          font-size: 32px;
        }
      }
    }
  }
  .why-us {
    .why-us-img {
      margin-left: 15px;
    }
    .why-us-list {
      margin-bottom: 20px;
      width: 100%;
      &:first-child,
      &:last-child {
        margin-left: -23%;
      }
      &:nth-child(2),
      &:nth-child(6) {
        margin-left: -11%;
      }
      &:nth-child(3),
      &:nth-child(5) {
        margin-left: -2%;
      }
      h5 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .process-section {
    .process-steps {
      width: auto;
      background-size: contain;
      .step-one {
        padding-left: 33%;
      }
      .step-three {
        padding-left: 46%;
        padding-top: 13%;
        width: 98%;
      }
      .step-five {
        padding-left: 43%;
      }
      h4 {
        font-size: 30px;
      }
      h3 {
        font-size: 32px;
      }
    }
  }
  .our-services {
    h3 {
      font-size: 50px;
    }
    p:not(.mb-0) {
      font-size: 18px;
    }
    ul {
      li {
        font-size: 18px;
      }
    }
  }
  .why-we {
    h2 {
      font-size: 32px;
    }
    p {
      padding-bottom: 10px;
      margin-bottom: 18px;
    }
  }
  .mini-banner {
    .slick-slider {
      .slick-list {
        min-height: 165px;
        .slick-track {
          .slick-slide {
            span {
              font-size: 30px;
            }
            &.slick-active {
              span {
                font-size: 40px;
              }
              &.slick-current {
                span {
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .journey-animation {
    > div {
      min-width: calc(100% + 170px);
      svg {
        margin: -80px 0 -18px -50px;
      }
    }
  }
  .contact-section {
    .contact-form {
      width: 500px;
      padding: 20px 30px;
      h2 {
        font-size: 25px;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 10px;
        margin-top: 0;
      }
      .btn-primary {
        padding: 10px 20px;
      }
      .form-control,
      .form-select {
        margin-bottom: 8px;
        padding: 7px 10px;
      }
    }
  }
  .company-journey {
    &.section-py {
      padding-bottom: 320px;
    }
    .journey-details {
      gap: 150px;
      &:nth-child(3) {
        padding-top: 35%;
      }
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(6),
      &:last-child {
        padding-top: 26%;
      }
      .journey-year {
        font-size: 35px;
      }
      .journey-content {
        font-size: 20px;
      }
    }
  }
  .about-vasundhara {
    .vasundhara-content {
      padding-block: 217px;
      h1 {
        font-size: 50px;
        width: 35%;
      }
      p {
        font-size: 17px;
      }
    }
  }
  .home-slider {
    .slider-img {
      .slider-content {
        padding-block: 180px;
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 17px;
        }
      }
    }
  }
  .service-slider {
    .slider-img {
      .slider-content {
        padding-block: 200px;
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 17px;
          width: 62%;
        }
      }
    }
  }
  .service-section {
    .service-list {
      padding: 40px 30px;
      gap: 35px;
      h3 {
        font-size: 200px;
      }
      h4 {
        font-size: 30px;
      }
      p {
        font-size: 18px;
        line-height: 35px;
      }
    }
  }
  .section-py {
    padding-block: 80px;
  }
  .section-heading {
    margin-bottom: 40px;
    img {
      width: 220px;
    }
    h2 {
      font-size: 38px;
    }
    p {
      font-size: 17px;
      margin-top: 30px;
    }
  }
  .logo-wrapper {
    width: 200px;
  }
  .btn-primary {
    padding: 12px 35px;
  }
  .btn-outline {
    padding: 8px 16px;
  }
  .header-wrapper {
    .header-content {
      .right-header {
        ul {
          padding-right: 30px;
          li {
            padding-right: 40px;
          }
        }
      }
    }
  }
  .our-portfolio {
    .nav-tabs {
      gap: 15px;
    }
    .portfolio-tab {
      margin-top: 20px;
      .nav-tabs {
        gap: 15px;
        justify-content: center;
      }
      .tab-content {
        h3 {
          font-size: 28px;
          top: 15px;
        }
      }
    }
  }
  .why-us {
    .why-us-img {
      margin-left: 10px;
    }
    .why-us-list {
      &:first-child,
      &:last-child {
        margin-left: -34%;
      }
      &:nth-child(2),
      &:nth-child(6) {
        margin-left: -16%;
      }
      &:nth-child(3),
      &:nth-child(5) {
        margin-left: -3%;
      }
      h5 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
        margin-top: 2px;
      }
    }
  }
  .process-section {
    .process-steps {
      margin-top: 10px;
      .step-one {
        margin-top: -10px;
      }
      .step-three {
        width: 100%;
        padding-top: 12%;
      }
      .step-four,
      .step-six {
        padding-top: 11%;
      }
      h4 {
        font-size: 25px;
      }
      h3 {
        font-size: 28px;
      }
      p {
        font-size: 17px;
        line-height: 35px;
      }
    }
  }
  .footer-wrapper {
    padding-bottom: 50px;
  }
  .about-us {
    .globe-container {
      background-size: contain;
      #threeGlobe {
        height: 500px;
        margin: -105px 0 -28px 0;
      }
    }
    p {
      font-size: 16px;
    }
    h2 {
      font-size: 32px;
      margin-bottom: 30px;
    }
    .about-content {
      gap: 12px;
      img {
        width: 65px;
      }
      h5 {
        font-size: 28px;
        margin-bottom: 4px;
        span {
          font-size: 28px;
          margin-bottom: 4px;
        }
      }
      span {
        font-size: 15px;
      }
    }
  }
  .why-we {
    .badge {
      font-size: 12px;
      padding: 6px 12px;
    }
    h2 {
      font-size: 26px;
      margin-top: 6px;
      margin-bottom: 4px;
    }
    p {
      font-size: 14px;
      padding-bottom: 6px;
      margin-bottom: 10px;
    }
    .why-we-content {
      img {
        width: 40px;
      }
      h6 {
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 2px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .blog-list {
    .blog-card {
      h5 {
        font-size: 17px;
      }
    }
  }
  .client-review {
    p {
      font-size: 22px;
    }
    h6 {
      font-size: 20px;
    }
    .client-img {
      img {
        width: 320px;
      }
      .img-effect {
        width: 318px;
        height: 378px;
      }
    }
    .slick-arrow {
      top: 82%;
    }
  }
  .mini-banner {
    .slick-slider {
      .slick-list {
        min-height: 135px;
        .slick-track {
          top: -10px;
          .slick-slide {
            span {
              font-size: 25px;
            }
            &.slick-active {
              span {
                font-size: 35px;
              }
              &.slick-current {
                span {
                  font-size: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .journey-animation {
    > div {
      min-width: 100%;
      svg {
        margin: -192px 0 -160px 15px;
      }
    }
  }
  .contact-section {
    .contact-form {
      margin: 50px auto;
      box-shadow: 0px 0px 25px 5px rgba($primary-color, 0.1);
    }
  }
  .company-journey {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.5);
    }
    .journey-details {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 50px;
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:last-child {
        padding-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .journey-year {
        font-size: 30px;
        order: -1;
        padding: 6px 30px;
      }
      .journey-content {
        font-size: 18px;
        width: 100%;
        padding: 20px;
      }
    }
  }
  .about-vasundhara {
    background-position-x: 70%;
    .vasundhara-content {
      h1 {
        font-size: 45px;
        width: 40%;
      }
      p {
        font-size: 16px;
        width: 50%;
      }
    }
  }
  .home-slider {
    .slider-img {
      background-position-x: 62%;
      .slider-content {
        h1 {
          font-size: 38px;
          width: 47%;
        }
        p {
          font-size: 16px;
          width: 64%;
        }
      }
    }
  }
  .service-slider {
    .slider-img {
      background-position-x: 62%;
      .slider-content {
        h1 {
          font-size: 38px;
          width: 52%;
        }
        p {
          font-size: 16px;
          width: 64%;
        }
      }
    }
  }
  .service-section {
    .service-list {
      padding: 30px 20px;
      text-align: center;
      margin-bottom: 20px;
      position: relative;
      h3 {
        font-size: 180px;
        position: absolute;
      }
      h4 {
        font-size: 25px;
        position: relative;
      }
      p {
        font-size: 17px;
        line-height: 30px;
        position: relative;
      }
    }
  }
  .our-portfolio {
    .nav-tabs {
      gap: 10px;
      .nav-item {
        .nav-link {
          font-size: 16px;
          padding: 8px 18px;
          background-color: rgba($primary-color, 0.04);
        }
      }
    }
    .portfolio-tab {
      .tab-content {
        h3 {
          font-size: 25px;
          top: 17px;
        }
      }
      .nav-tabs {
        .nav-item {
          .nav-link {
            background-color: transparent;
            &.active {
              font-size: 14px;
              background-color: $primary-color;
            }
          }
        }
      }
    }
  }
  .why-us {
    .why-us-img {
      display: none;
    }
    .why-us-list {
      text-align: center;
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6),
      &:last-child {
        margin-left: 0;
      }
      p {
        font-size: 16px;
        margin-top: 4px;
      }
    }
  }
  .process-section {
    .process-steps {
      background-image: none;
      margin-top: 0;
      .step-one,
      .step-two,
      .step-three,
      .step-four,
      .step-five,
      .step-six {
        margin-bottom: 20px;
        width: 100%;
        background: linear-gradient(
          180deg,
          $gradient-dark -27.38%,
          transparent 100%
        );
        padding: 12px 20px;
        border-radius: 14px;
      }
      .step-one {
        margin-top: 0;
      }
      .step-six {
        margin-bottom: 0;
      }
      h3 {
        display: none;
      }
      h4 {
        font-size: 22px;
        text-align: center;
      }
      p {
        font-size: 16px;
        text-align: center;
        color: $why-us-text;
        line-height: 30px;
      }
    }
  }
  .technologies-section {
    .technologies-list {
      gap: 40px;
      .technologies-name {
        .technologies-img {
          width: 150px;
          height: 150px;
          img {
            width: 75px;
          }
        }
      }
    }
  }
  .section-heading {
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 16px;
      margin-top: 25px;
    }
    img {
      display: none;
    }
  }
  .header-wrapper {
    .header-content {
      .left-header {
        display: flex;
        align-items: center;
        gap: 40px;
        .toggle-bar {
          display: block;
        }
      }
      .right-header {
        ul {
          display: none;
        }
      }
    }
  }
  .footer-wrapper {
    background-image: url(../../../public/images/png/res-footer-bg.webp);
    background-position: top;
    h3 {
      margin-bottom: 20px;
      margin-top: 10px;
      font-size: 25px;
    }
    .logo-wrapper {
      margin-bottom: 30px;
    }
    .contact-info {
      flex-direction: column;
      margin-top: 50px;
      gap: 4px;
      h4 {
        font-size: 20px;
      }
      ul {
        li {
          margin-bottom: 0;
        }
      }
    }
    .sub-footer {
      p {
        margin-top: 4px;
      }
      .social-icons {
        justify-content: center;
      }
    }
  }
  .about-us {
    .globe-container {
      #threeGlobe {
        margin: -105px 0 0;
      }
    }
    h2 {
      text-align: left;
      margin-bottom: 25px;
      font-size: 28px;
    }
    .about-content {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
  .our-services {
    .services-video {
      background: unset;
      border: none;
      border-radius: 0;
    }
    ul {
      display: none;
    }
    h3 {
      margin-inline: auto;
      width: 35%;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
    }
    p:not(.mb-0) {
      text-align: center;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 20px;
    }
    button {
      display: block;
      margin-inline: auto;
    }
  }
  .client-review {
    p {
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 20px;
    }
    h6 {
      font-size: 18px;
    }
    span {
      font-size: 16px;
    }
    .client-img {
      order: -1;
      padding-block: 0;
      img {
        width: auto;
        filter: unset;
        margin-inline: auto;
      }
      .img-effect {
        display: none;
      }
    }
    .slick-slider {
      padding-bottom: 70px;
      .slick-slide {
        padding-inline: 10px;
      }
      .slick-arrow {
        top: unset;
        bottom: -14px;
        &.slick-prev {
          background-color: $primary-color;
          border: none;
          left: -6px;
        }
        &.slick-next {
          left: 58px;
        }
      }
    }
  }
  .blog-list {
    .btn-primary {
      margin-top: 50px;
      border-radius: 50px;
      padding: 10px 25px;
    }
    .slick-slider {
      .slick-track {
        padding-bottom: 10px;
        .slick-slide {
          padding-inline: 10px;
        }
      }
      .slick-dots {
        li {
          button {
            &::before {
              font-size: 12px;
              opacity: 1;
              color: $mini-banner;
            }
          }
          &.slick-active {
            button {
              &::before {
                content: "\f111";
                font-family: "Font Awesome 6 Free";
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
  .mini-banner {
    h2 {
      font-size: 35px;
    }
    p {
      margin-top: 10px;
    }
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            span {
              color: $mini-banner;
              background: none;
              -webkit-text-fill-color: unset;
            }
            &.slick-active {
              span {
                color: $light-orange;
              }
              &.slick-current {
                span {
                  color: $mini-banner;
                  background: none;
                  -webkit-text-fill-color: unset;
                }
              }
            }
          }
        }
      }
    }
  }
  .custom-cursor {
    display: none;
  }
}
@media (max-width: 767px) {
  .journey-animation {
    > div {
      svg {
        margin: -142px 0 -120px 10px;
      }
    }
  }
  .company-journey {
    &.section-py {
      padding-bottom: 260px;
    }
    .journey-details {
      gap: 15px;
      margin-bottom: 30px;
      .journey-year {
        font-size: 25px;
      }
      .journey-content {
        font-size: 16px;
      }
    }
  }
  .home-slider {
    .slider-img {
      background-position-x: center;
      background-position-y: 30%;
      .slider-content {
        text-align: center;
        padding-top: 100%;
        padding-bottom: 100px;
        h1 {
          font-size: 34px;
          width: 80%;
          margin-inline: auto;
        }
        p {
          margin: 15px auto;
          width: 75%;
        }
        button {
          margin: auto;
        }
        .slider-pagination {
          right: 0;
          bottom: 30px;
          h5 {
            font-size: 25px;
            span {
              font-size: 15px;
            }
          }
        }
      }
    }
    .slick-dots {
      bottom: 50px;
    }
  }
  .service-slider {
    .slider-img {
      background-position-x: center;
      background-position-y: 30%;
      .slider-content {
        text-align: center;
        padding-top: 100%;
        padding-bottom: 100px;
        h1 {
          font-size: 34px;
          width: 80%;
          margin-inline: auto;
        }
        p {
          margin: 15px auto;
          width: 86%;
        }
        button {
          margin: auto;
        }
      }
    }
  }
  .about-vasundhara {
    background-image: url(../../../public/images/png/res-vasundhara-bg.webp);
    background-position-x: center;
    background-position-y: 30%;
    .vasundhara-content {
      text-align: center;
      padding-top: 100%;
      padding-bottom: 100px;
      h1 {
        font-size: 40px;
        width: 50%;
        margin-inline: auto;
      }
      p {
        margin: 15px auto;
        width: 85%;
      }
      button {
        margin: auto;
      }
    }
  }
  .our-portfolio {
    .nav-tabs {
      gap: 15px;
    }
    .portfolio-tab {
      .tab-content {
        h3 {
          font-size: 20px;
        }
      }
      .nav-tabs {
        padding-top: 60px;
      }
    }
  }
  .technologies-section {
    .technologies-list {
      gap: 30px;
      .technologies-name {
        .technologies-img {
          width: 140px;
          height: 140px;
          img {
            width: 65px;
          }
        }
      }
    }
  }
  .section-py {
    padding-block: 60px;
  }
  .section-heading {
    p {
      font-size: 15px;
      margin-top: 20px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .btn-primary {
    padding: 10px 20px;
    font-size: 16px;
  }
  .btn-outline {
    padding: 7px 14px;
    font-size: 16px;
  }
  .header-wrapper {
    .header-content {
      .left-header {
        gap: 30px;
      }
    }
  }
  .about-us {
    h2 {
      margin-bottom: 20px;
      font-size: 25px;
    }
  }
  .our-services {
    h3 {
      width: 45%;
    }
  }
  .why-we {
    h2 {
      font-size: 22px;
    }
  }
  .footer-wrapper {
    padding-bottom: 40px;
  }
}
@media (max-width: 575px) {
  .journey-animation {
    > div {
      svg {
        margin: -102px 0 -89px 10px;
      }
    }
  }
  .contact-section {
    .contact-form {
      width: calc(100% - 40px);
      margin: 20px;
      padding: 15px;
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
      .btn-primary {
        padding: 7px 14px;
      }
      .form-control,
      .form-select {
        font-size: 15px;
      }
      .form-select {
        white-space: normal;
        padding-right: 25px;
      }
    }
  }
  .company-journey {
    &.section-py {
      padding-bottom: 230px;
    }
    .journey-details {
      gap: 10px;
      margin-bottom: 20px;
      .journey-year {
        font-size: 20px;
        padding: 6px 20px;
      }
      .journey-content {
        padding: 15px;
      }
    }
  }
  .home-slider {
    .slider-img {
      .slider-content {
        padding-bottom: 70px;
        h1 {
          font-size: 25px;
          width: 100%;
        }
        p {
          font-size: 14px;
          width: 100%;
        }
        .slider-pagination {
          h5 {
            font-size: 20px;
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
    .slick-dots {
      bottom: 30px;
      li {
        width: 20px;
        button {
          &::after {
            width: 10px;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .service-slider {
    .slider-img {
      .slider-content {
        padding-bottom: 50px;
        h1 {
          font-size: 25px;
          width: 100%;
        }
        p {
          font-size: 14px;
          width: 100%;
        }
      }
    }
  }
  .about-vasundhara {
    &::before {
      display: none;
    }
    .vasundhara-content {
      padding-bottom: 50px;
      h1 {
        font-size: 25px;
        width: 100%;
      }
      p {
        font-size: 14px;
        width: 100%;
      }
    }
  }
  .our-portfolio {
    .nav-tabs {
      gap: 10px;
      .nav-item {
        .nav-link {
          padding: 6px 12px;
          font-size: 15px;
        }
      }
    }
    .portfolio-tab {
      .tab-content {
        h3 {
          font-size: 17px;
        }
      }
      .nav-tabs {
        gap: 14px;
        padding-top: 54px;
        padding-bottom: 20px;
        margin-bottom: 10px;
        .nav-item {
          .nav-link {
            padding: 5px 10px;
          }
        }
      }
    }
  }
  .section-heading {
    margin-bottom: 25px;
    h2 {
      font-size: 25px;
    }
    p {
      font-size: 14px;
      margin-top: 15px;
    }
  }
  .section-py {
    padding-block: 40px;
  }
  .logo-wrapper {
    width: 150px;
  }
  .header-wrapper {
    .header-content {
      padding-block: 12px;
      .left-header {
        gap: 15px;
        .toggle-bar {
          width: 27px;
        }
      }
    }
  }
  .footer-wrapper {
    padding-bottom: 30px;
    .logo-wrapper {
      margin-bottom: 20px;
    }
    h3 {
      font-size: 20px;
    }
    ul {
      li {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
    .contact-info {
      margin-top: 20px;
      h4 {
        font-size: 17px;
      }
      p {
        font-size: 16px;
      }
    }
    .sub-footer {
      margin-top: 40px;
      span,
      p {
        font-size: 16px;
      }
      .social-icons {
        gap: 20px;
      }
    }
  }
  .service-section {
    .service-list {
      padding: 15px;
      margin-bottom: 15px;
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
  .why-us {
    .why-us-list {
      padding: 12px;
    }
  }
  .process-section {
    .process-steps {
      h4 {
        font-size: 20px;
      }
    }
  }
  .technologies-section {
    .technologies-list {
      gap: 20px;
      flex-direction: column;
      .technologies-name {
        display: flex;
        align-items: center;
        gap: 80px;
        h5 {
          display: block;
        }
        .technologies-img {
          width: 100px;
          height: 100px;
          img {
            width: 50px;
          }
        }
      }
    }
  }
  .about-us {
    .globe-container {
      position: relative;
      #threeGlobe {
        height: 400px;
        margin: -80px 0 55px;
      }
      .client-slider {
        position: absolute;
        bottom: -55px;
        width: 100%;
        padding-block: 50px;
      }
    }
    h2 {
      margin-bottom: 15px;
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
    .about-content {
      flex-direction: column;
      text-align: center;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 20px;
      }
      h5 {
        font-size: 20px;
        margin-bottom: 0;
        span {
          font-size: 20px;
          margin-bottom: 0;
        }
      }
    }
  }
  .our-services {
    h3 {
      font-size: 30px;
      width: 100%;
    }
    .services-video {
      .play-btn {
        width: 60px;
        height: 60px;
        svg {
          font-size: 20px;
        }
      }
    }
  }
  .why-we {
    h2 {
      font-size: 18px;
    }
  }
  .client-review {
    p {
      font-size: 17px;
      margin-bottom: 25px;
    }
    h6 {
      font-size: 16px;
    }
    span {
      font-size: 15px;
    }
    .slick-slider {
      padding-bottom: 50px;
      .slick-arrow {
        width: 35px;
        height: 35px;
        bottom: -12px;
        &::before {
          font-size: 16px;
        }
        &.slick-prev {
          left: -2px;
        }
        &.slick-next {
          left: 46px;
        }
      }
    }
  }
  .mini-banner {
    h2 {
      font-size: 30px;
    }
  }
}
@media (max-width: 420px) {
  .journey-animation {
    > div {
      svg {
        margin: -75px 0 -65px 7px;
      }
    }
  }
  .logo-wrapper {
    width: 120px;
  }
  .btn-primary {
    padding: 4px 8px;
    font-size: 14px;
  }
  .btn-outline {
    padding: 6px 12px;
    font-size: 14px;
  }
  .header-wrapper {
    .header-content {
      .left-header {
        .toggle-bar {
          width: 22px;
        }
      }
    }
  }
  .mobile-sidebar {
    &.offcanvas {
      .offcanvas-header {
        padding: 30px;
      }
      .offcanvas-body {
        ul {
          li {
            a {
              padding: 20px 30px;
              gap: 20px;
            }
          }
        }
      }
    }
  }
  .technologies-section {
    .technologies-list {
      .technologies-name {
        gap: 40px;
      }
    }
  }
  .about-us {
    .globe-container {
      #threeGlobe {
        height: 300px;
        margin: -60px 0 40px;
      }
      .client-slider {
        gap: 15px;
        .client-detail {
          gap: 15px;
          img {
            width: 100px;
          }
        }
      }
    }
    .about-content {
      span {
        font-size: 14px;
      }
    }
  }
  .our-services {
    h3 {
      font-size: 25px;
    }
    p:not(.mb-0) {
      font-size: 14px;
    }
    .services-video {
      .play-btn {
        width: 50px;
        height: 50px;
        svg {
          font-size: 18px;
        }
      }
    }
  }
  .mini-banner {
    .slick-slider {
      .slick-list {
        min-height: unset;
        .slick-track {
          top: -20px;
          .slick-slide {
            span {
              font-size: 17px;
            }
            &.slick-active {
              span {
                font-size: 25px;
              }
              &.slick-current {
                span {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
