* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Satoshi-Variable";
  background-color: #000000;
  overflow-y: auto;
  overflow-x: hidden;
}

::selection {
  background-color: #0192ED;
  color: #ffffff;
}

a {
  text-decoration: none;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

ul li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.section-py {
  padding-block: 100px;
}

.light-bg {
  background-color: #ffffff;
}

.btn-primary {
  background-color: #0192ED;
  border-color: #0192ED;
  border-radius: 10px;
  padding: 14px 50px;
  font-size: 17px;
  font-weight: 700;
  color: #ffffff;
  transition: all 0.4s;
}

.btn-primary:hover {
  background-color: #0192ED;
  border-color: #0192ED;
  color: #ffffff;
  transform: scale(0.97);
}

.btn-outline {
  background-color: #000000;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  transition: all 0.4s;
}

.btn-outline::after {
  content: "";
  background: rgba(255, 255, 255, 0.2);
  width: 50px;
  height: 155px;
  position: absolute;
  left: -75px;
  top: -50px;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(35deg);
}

.btn-outline:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
  background-color: #000000;
}

.btn-outline:hover::after {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.header-wrapper {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  transition: all 0.4s;
}

.header-wrapper .header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 20px;
}

.header-wrapper .header-content .left-header .toggle-bar {
  cursor: pointer;
  width: 35px;
  display: none;
}

.header-wrapper .header-content .right-header {
  display: flex;
  align-items: center;
}

.header-wrapper .header-content .right-header ul {
  display: flex;
  padding-right: 50px;
}

.header-wrapper .header-content .right-header ul li {
  padding-right: 80px;
}

.header-wrapper .header-content .right-header ul li:last-child {
  padding-right: 0;
}

.header-wrapper .header-content .right-header ul li a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}

.header-wrapper .header-content .right-header ul li a.active {
  color: #0192ED;
}

.header-wrapper.header-sticky {
  position: sticky;
  background-color: #000000;
}

.header-wrapper.header-sticky .header-content {
  padding-block: 12px;
}

.header-wrapper.header-unset {
  position: unset;
}

.home-slider {
  position: relative;
}

.home-slider::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #000000 -20.75%, rgba(0, 0, 0, 0) 63.52%);
  width: 100%;
  height: 170px;
  z-index: 1;
}

.home-slider .slider-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.home-slider .slider-img .slider-content {
  padding-block: 220px;
  position: relative;
}

.home-slider .slider-img .slider-content h1 {
  color: #ffffff;
  font-size: 52px;
  font-weight: 900;
  width: 35%;
}

.home-slider .slider-img .slider-content p {
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  width: 52%;
  margin-block: 28px;
}

.home-slider .slider-img .slider-content .slider-pagination {
  position: absolute;
  right: 50px;
  bottom: 75px;
}

.home-slider .slider-img .slider-content .slider-pagination h5 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
}

.home-slider .slider-img .slider-content .slider-pagination h5 span {
  color: rgba(255, 255, 255, 0.2);
  font-size: 18px;
  margin-left: 7px;
  letter-spacing: 2px;
}

.home-slider .slick-dots {
  bottom: 70px;
}

.home-slider .slick-dots li {
  width: 40px;
}

.home-slider .slick-dots li.slick-active button::before {
  color: #ffffff;
}

.home-slider .slick-dots li button::before {
  color: #ffffff;
}

.home-slider .slick-dots li button::after {
  content: "";
  display: flex;
  width: 20px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-left: 20px;
}

.home-slider .slick-dots li:last-child {
  width: 20px;
}

.home-slider .slick-dots li:last-child button::after {
  display: none;
}

.about-us .globe-container {
  background-image: url(../../../public/images/png/globe-layer.webp);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 84%;
}

.about-us .globe-container #threeGlobe {
  max-width: 100%;
  height: 700px;
  margin: -145px 0 -28px 0;
  cursor: grab;
  display: flex;
  justify-content: center;
}

.about-us .globe-container .client-slider {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}

.about-us .globe-container .client-slider .client-detail {
  display: flex;
  align-items: center;
  gap: 20px;
  animation: scroll-slider 30s linear infinite;
}

.about-us .globe-container .client-slider .client-detail img {
  width: 120px;
  border-radius: 10px;
  box-shadow: 0px 5px 74px 0px rgba(0, 0, 0, 0.1);
}

.about-us h2 {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: #132028;
  margin-bottom: 40px;
  position: relative;
}

.about-us h2 span {
  color: #0192ED;
  font-style: italic;
}

.about-us p {
  color: #44464A;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.about-us .about-content {
  gap: 20px;
  margin-bottom: 50px;
}

.about-us .about-content:last-child {
  margin-bottom: 0;
}

.about-us .about-content h5 {
  font-size: 36px;
  font-weight: 700;
  color: #132028;
  margin-bottom: 10px;
}

.about-us .about-content h5 span {
  font-size: 36px;
  font-weight: 700;
  color: #132028;
  margin-bottom: 10px;
}

.about-us .about-content span {
  font-size: 16px;
  font-weight: 500;
  color: #44464A;
}

@keyframes scroll-slider {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.our-services h3 {
  color: #ffffff;
  font-size: 60px;
  font-weight: 900;
  width: 60%;
}

.our-services p:not(.mb-0) {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
}

.our-services ul {
  padding-left: 15px;
  margin-block: 22px;
}

.our-services ul li {
  list-style: disc;
  color: #828384;
  font-size: 20px;
}

.our-services .services-video {
  position: relative;
  border: 1px solid transparent;
  background: linear-gradient(90deg, #000000, rgba(255, 255, 255, 0.2) 50%) border-box;
  border-radius: 0 40px 40px 0;
  overflow: hidden;
}

.our-services .services-video::after {
  content: "";
  position: absolute;
  /* background: radial-gradient(ellipse at center, transparent 35%, rgba(0, 0, 0, 0.8) 65%, #000000 92%); */
  background: radial-gradient(ellipse at center, transparent 40%, rgba(0, 0, 0, 1) 72%, #000000 92%);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.our-services .services-video video {
  object-fit: cover;
}

.our-services .services-video .play-btn {
  position: absolute;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 1px solid #ffffff;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.our-services .services-video .play-btn svg {
  color: #ffffff;
  font-size: 25px;
}

/* Vishal Change start */

 .our-services .services-video-center {
  position: relative;
  border: 1px solid transparent;
  background: linear-gradient(270deg, #000000, rgba(255, 255, 255, 0.2) 50%) border-box;
  /* background: linear-gradient(270deg, #000000, rgba(255, 255, 255, 0.2) 50%) border-box; */
  border-radius: 40px 0 0 40px;
  overflow: hidden;
}
.our-services .services-video-center::after {
  content: "";
  position: absolute;
  background: radial-gradient(ellipse at center, transparent 40%, rgba(0, 0, 0, 1) 72%, #000000 92%);
  /* background: radial-gradient(ellipse at center, transparent 35%, rgba(0, 0, 0, 0.8) 57%, #000000 75%); */
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.our-services .services-video-center video {
  object-fit: cover;
}
.our-services .services-video-center .play-btn {
  position: absolute;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 1px solid #ffffff;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.our-services .services-video-center .play-btn svg {
  color: #ffffff;
  font-size: 25px;
} 






/* Vishal Change end*/

.why-we .badge {
  background-color: #E0F8DE;
  color: #44464A;
  padding: 8px 14px;
  border-radius: 70px;
  font-size: 14px;
  font-weight: 500;
}

.why-we h2 {
  font-size: 36px;
  color: #132028;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 10px;
}

.why-we p {
  color: #44464A;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 22px;
  text-transform: capitalize;
}

.why-we .why-we-content h6 {
  font-size: 16px;
  font-weight: 700;
  color: #132028;
  margin-top: 14px;
  margin-bottom: 8px;
}

.why-we .why-we-content p {
  font-size: 14px;
  font-weight: 500;
  color: #44464A;
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
  text-transform: unset;
}

.client-review .client-img {
  position: relative;
  padding-block: 30px;
}

.client-review .client-img img {
  filter: grayscale(1);
  transition: all 0.4s;
}

.client-review .client-img .img-effect {
  position: absolute;
  background-color: #F9B9BB;
  width: 420px;
  height: 500px;
  border-radius: 10px;
  top: 30px;
  left: 12px;
  rotate: 6deg;
  z-index: -1;
  transition: all 0.4s;
}

.client-review .client-img:hover img {
  filter: grayscale(0);
}

.client-review .client-img:hover .img-effect {
  rotate: -6deg;
}

.client-review p {
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  margin-top: -85px;
  margin-bottom: 110px;
}

.client-review h6 {
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 5px;
}

.client-review span {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}

.client-review .slick-arrow {
  top: 78%;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  z-index: 1;
  rotate: 50deg;
}

.client-review .slick-arrow.slick-prev {
  left: -17px;
  border: 1px solid #ffffff;
}

.client-review .slick-arrow.slick-prev::before {
  content: "\f063";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
}

.client-review .slick-arrow.slick-next {
  right: unset;
  left: 48px;
  background-color: #0192ED;
}

.client-review .slick-arrow.slick-next::before {
  content: "\f062";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
}

.blog-list .btn-primary {
  padding: 14px 35px;
  margin-top: 42px;
}

.blog-list .btn-primary svg {
  font-size: 12px;
}

.blog-list .blog-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 1.66px 12.42px 0px rgba(0, 0, 0, 0.1);
  padding: 14px;
}

.blog-list .blog-card:hover .blog-img img {
  transform: scale(1.1);
}

.blog-list .blog-card .blog-img {
  overflow: hidden;
}

.blog-list .blog-card .blog-img img {
  width: 100%;
  transition: all 0.4s;
}

.blog-list .blog-card span {
  color: #44464A;
  font-weight: 500;
  font-size: 12px;
  margin-block: 14px;
}

.blog-list .blog-card h5 {
  font-size: 20px;
  font-weight: 700;
  color: #132028;
}

.blog-list .blog-card p {
  font-size: 14px;
  font-weight: 500;
  color: #44464A;
  margin-top: 12px;
  margin-bottom: 25px;
}

.blog-list .blog-card a {
  font-size: 14px;
  font-weight: 500;
  color: #0192ED;
}

.blog-list .blog-card a svg {
  font-size: 10px;
}

.journey-animation>div {
  min-width: calc(100% + 230px);
}

.journey-animation>div svg {
  margin: -100px 0 -22px -65px;
  cursor: default;
}

.mini-banner {
  background-image: url(../../../public/images/png/mini-banner.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mini-banner h2 {
  font-size: 40px;
  font-weight: 700;
  color: #000000;
}

.mini-banner p {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-top: 35px;
  margin-bottom: 15px;
}

.mini-banner .btn-primary {
  padding: 12px 30px;
}

.mini-banner .slick-slider .slick-list {
  min-height: 184px;
}

.mini-banner .slick-slider .slick-list .slick-track .slick-slide span {
  font-size: 36px;
  font-weight: 900;
  background: linear-gradient(#ffffff 20%, #5A6369 50%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active span {
  color: #0192ED;
  -webkit-text-fill-color: unset;
  font-size: 50px;
}

.mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active.slick-current span {
  background: linear-gradient(to top, #ffffff 20%, #5A6369 40%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 36px;
}

.footer-wrapper {
  background-image: url(../../../public/images/png/footer-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 60px;
}

.footer-wrapper h3 {
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 45px;
}

.footer-wrapper ul li {
  display: flex;
  align-items: center;
  gap: 14px;
  font-size: 17px;
  font-weight: 400;
  color: #ACACAC;
  margin-bottom: 28px;
}

.footer-wrapper ul li a {
  color: #ACACAC;
}

.footer-wrapper .contact-info {
  display: flex;
  gap: 10px;
  margin-top: 65px;
}

.footer-wrapper .contact-info h4 {
  font-size: 25px;
  font-weight: 500;
  color: #ffffff;
  flex-shrink: 0;
}

.footer-wrapper .contact-info p {
  font-size: 17px;
  font-weight: 500;
  color: #ACACAC;
  margin-bottom: 0;
  margin-top: 5px;
}

.footer-wrapper .contact-info ul {
  margin-top: 5px;
}

.footer-wrapper .contact-info ul li {
  margin-bottom: 4px;
  font-weight: 500;
}

.footer-wrapper .sub-footer {
  margin-top: 60px;
}

.footer-wrapper .sub-footer .social-icons {
  display: flex;
  align-items: center;
  gap: 27px;
}

.footer-wrapper .sub-footer span {
  font-size: 17px;
  font-weight: 400;
  color: #ffffff;
}

.footer-wrapper .sub-footer p {
  font-size: 17px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 0;
  margin-top: 10px;
}

.service-slider {
  position: relative;
}

.service-slider::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #000000 -20.75%, rgba(0, 0, 0, 0) 63.52%);
  width: 100%;
  height: 170px;
  z-index: 1;
}

.service-slider .slider-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.service-slider .slider-img .slider-content {
  padding-block: 250px;
  position: relative;
}

.service-slider .slider-img .slider-content h1 {
  color: #ffffff;
  font-size: 52px;
  font-weight: 900;
  width: 40%;
}

.service-slider .slider-img .slider-content p {
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  width: 52%;
  margin-block: 28px;
}

.service-slider .slick-dots {
  bottom: 70px;
}

.service-slider .slick-dots li {
  width: 40px;
}

.service-slider .slick-dots li.slick-active button::before {
  color: #ffffff;
}

.service-slider .slick-dots li button::before {
  color: #ffffff;
}

.service-slider .slick-dots li button::after {
  content: "";
  display: flex;
  width: 20px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-left: 20px;
}

.service-slider .slick-dots li:last-child {
  width: 20px;
}

.service-slider .slick-dots li:last-child button::after {
  display: none;
}

.service-section .service-list {
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  gap: 42px;
  border-radius: 20px;
  padding: 48px 36px;
  margin-bottom: 27px;
}

.service-section .service-list:last-child {
  margin-bottom: 0;
}

.service-section .service-list h3 {
  color: #dddddd;
  font-size: 220px;
  font-weight: 700;
  line-height: 0.8;
  width: 130px;
  flex-shrink: 0;
}

.service-section .service-list h4 {
  color: #0192ED;
  font-size: 36px;
  font-weight: 700;
}

.service-section .service-list p {
  color: #000000;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 5px;
  line-height: 40px;
}

.why-us .why-us-img {
  margin-left: 30px;
}

.why-us .why-us-list {
  background: linear-gradient(180deg, #252525 -27.38%, transparent 100%);
  padding: 12px 20px;
  border-radius: 14px;
  margin-bottom: 34px;
  width: 97%;
}

.why-us .why-us-list:first-child {
  margin-left: -22%;
  animation-delay: 0.1s;
}

.why-us .why-us-list:nth-child(2) {
  margin-left: -10%;
  animation-delay: 0.2s;
}

.why-us .why-us-list:nth-child(3) {
  margin-left: -1%;
  animation-delay: 0.3s;
}

.why-us .why-us-list:nth-child(5) {
  margin-left: -1%;
  animation-delay: 0.4s;
}

.why-us .why-us-list:nth-child(6) {
  margin-left: -10%;
  animation-delay: 0.5s;
}

.why-us .why-us-list:last-child {
  margin-left: -22%;
  margin-bottom: 0;
  animation-delay: 0.6s;
}

.why-us .why-us-list h5 {
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
}

.why-us .why-us-list p {
  font-size: 17px;
  font-weight: 300;
  color: #6B6B6B;
  margin-bottom: 0;
  margin-top: 5px;
}

.our-portfolio .nav-tabs {
  border: 0;
  gap: 42px;
}

.our-portfolio .nav-tabs .nav-item .nav-link {
  border: 0;
  font-size: 17px;
  font-weight: 500;
  color: #44464A;
  padding: 8px 37px;
  border-radius: 50px;
}

.our-portfolio .nav-tabs .nav-item .nav-link.active {
  background-color: #0192ED;
  color: #ffffff;
}

.our-portfolio .portfolio-tab {
  background-color: #132028;
  padding: 18px;
  border-radius: 10px;
  margin-top: 30px;
}

.our-portfolio .portfolio-tab img {
  width: 100%;
}

.our-portfolio .portfolio-tab .nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 25px;
  margin-bottom: 14px;
  padding-top: 70px;
  align-items: center;
  justify-content: space-between;
}

.our-portfolio .portfolio-tab .nav-tabs .nav-item .nav-link {
  font-size: 14px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 50px;
  padding: 5px 12px;
}

.our-portfolio .portfolio-tab .nav-tabs .nav-item .nav-link.active {
  font-size: 16px;
  border: 0;
  outline: 4px solid #0192ED;
  outline-offset: -1px;
}

.our-portfolio .portfolio-tab .tab-content h3 {
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
  position: absolute;
  top: 10px;
  width: 100%;
  text-align: center;
}

.our-portfolio .portfolio-tab .tab-content p {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.technologies-section .technologies-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.technologies-section .technologies-list .technologies-name h5 {
  color: #0192ED;
  font-weight: 700;
  font-size: 18px;
  display: none;
}

.technologies-section .technologies-list .technologies-name .technologies-img {
  width: 165px;
  height: 165px;
  border-radius: 100%;
  background: linear-gradient(180deg, #252525 -27.38%, transparent 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.technologies-section .technologies-list .technologies-name .technologies-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid transparent;
  background: linear-gradient(to top, #000000 0%, #252525 100%) border-box;
  mask: linear-gradient(#ffffff 0 0) padding-box, linear-gradient(#ffffff 0 0);
  mask-composite: exclude;
}

.process-section .process-steps {
  background-image: url(../../../public/images/png/process-bg.webp);
  background-repeat: no-repeat;
  width: 1150px;
  height: auto;
  margin-top: 15px;
}

.process-section .process-steps h4 {
  color: #0192ED;
  font-size: 36px;
  font-weight: 700;
}

.process-section .process-steps p {
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 5px;
  line-height: 40px;
}

.process-section .process-steps h3 {
  color: #ffffff;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  padding-top: 10%;
}

.process-section .process-steps .step-one {
  width: 97%;
  padding-left: 31%;
  margin-top: -15px;
}

.process-section .process-steps .step-two {
  width: 76%;
  padding-left: 9%;
  padding-top: 12%;
}

.process-section .process-steps .step-three {
  width: 100%;
  padding-left: 43%;
  padding-top: 12%;
}

.process-section .process-steps .step-four {
  width: 74%;
  padding-left: 9%;
  padding-top: 12%;
}

.process-section .process-steps .step-five {
  width: 98%;
  padding-left: 40%;
  padding-top: 12%;
}

.process-section .process-steps .step-six {
  width: 78%;
  padding-left: 9%;
  padding-top: 12%;
}

.about-vasundhara {
  background-image: url(../../../public/images/png/vasundhara-bg.webp);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-vasundhara::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #000000 -20.75%, rgba(0, 0, 0, 0) 63.52%);
  width: 100%;
  height: 170px;
  z-index: 1;
}

.about-vasundhara .vasundhara-content {
  padding-block: 267px;
}

.about-vasundhara .vasundhara-content h1 {
  font-size: 60px;
  font-weight: 900;
  color: #ffffff;
  width: 30%;
}

.about-vasundhara .vasundhara-content h1 span {
  color: #0192ED;
}

.about-vasundhara .vasundhara-content p {
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  width: 45%;
  margin-bottom: 18px;
  margin-top: 14px;
}

.company-journey {
  background-image: url(../../../public/images/png/journey-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  padding-bottom: calc(25% + 10px);
}

.company-journey .journey-details {
  display: flex;
  align-items: center;
  gap: 200px;
  padding-top: 100%;
}

.company-journey .journey-details:nth-child(odd) {
  justify-content: flex-end;
}

.company-journey .journey-details:first-child {
  padding-top: 25%;
}

.company-journey .journey-details:nth-child(3) {
  padding-top: 28%;
}

.company-journey .journey-details:nth-child(4) {
  padding-top: 18%;
}

.company-journey .journey-details:nth-child(2),
.company-journey .journey-details:nth-child(5),
.company-journey .journey-details:nth-child(6),
.company-journey .journey-details:last-child {
  padding-top: 24%;
}

.company-journey .journey-details .journey-year {
  background: linear-gradient(rgba(1, 146, 237, 0.4) -27.38%, transparent 100%);
  border-radius: 10px;
  color: #0192ED;
  font-size: 52px;
  font-weight: 700;
  padding: 6px 44px;
}

.company-journey .journey-details .journey-content {
  background: linear-gradient(180deg, #252525 -27.38%, transparent 100%);
  border-radius: 10px;
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  padding: 30px;
  width: 41%;
  text-align: center;
}

.contact-section {
  background-color: #ffffff;
}

.contact-section .contact-form {
  background-color: #ffffff;
  margin-left: -120px;
  width: 620px;
  padding: 27px 45px;
}

.contact-section .contact-form h2 {
  font-size: 32px;
  font-weight: 700;
  color: #132028;
  text-shadow: 0px 2px 0px #0192ED;
  text-align: center;
  margin-bottom: 37px;
}

.contact-section .contact-form p {
  color: #44464A;
  font-size: 15px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 25px;
}

.contact-section .contact-form p a {
  color: #0192ED;
}

.contact-section .contact-form .btn-primary {
  border-radius: 50px;
  padding: 12px 25px;
}

.contact-section .contact-form .btn-primary svg {
  rotate: 45deg;
}

.contact-section .contact-form .form-control,
.contact-section .contact-form .form-select {
  border-color: #EEEEEE;
  padding: 10px 12px;
  margin-bottom: 18px;
  color: #44464A;
  transition: all 0.4s;
}

.contact-section .contact-form .form-control:focus,
.contact-section .contact-form .form-select:focus {
  border-color: #0192ED;
  box-shadow: 0 2px 10px 5px rgba(1, 146, 237, 0.07);
}

.contact-section .contact-form .form-control::placeholder,
.contact-section .contact-form .form-select::placeholder {
  color: #44464A;
}

.contact-section .contact-form .form-select {
  background-image: url(../../../public/images/svg/select-arrow.svg);
  background-size: unset;
}

.section-heading {
  margin: 0 auto 60px;
  text-transform: capitalize;
}

.section-heading h2 {
  font-size: 46px;
  font-weight: 700;
  text-shadow: 0px 2px 0px #0192ED;
}

.section-heading p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  text-align: center;
  margin-top: 40px;
}

.top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #0192ED;
  color: #ffffff;
  box-shadow: 0 1px 20px 1px #0192ED;
}

.custom-cursor {
  width: 40px;
  height: 40px;
  border: 1px solid #0192ED;
  border-radius: 100%;
  position: fixed;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  animation: cursor-animation 0.5s infinite alternate;
  pointer-events: none;
  z-index: 9;
  box-shadow: 0 1px 4px 8px rgba(1, 146, 237, 0.1);
}

.custom-cursor::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #0192ED;
  position: absolute;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes cursor-animation {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.8);
  }
}

.mobile-sidebar.offcanvas {
  background-color: #000000;
  border-right: 1px solid #252525;
}

.mobile-sidebar.offcanvas .offcanvas-header {
  padding: 40px;
}

.mobile-sidebar.offcanvas .offcanvas-header .btn-close {
  filter: invert(1);
  box-shadow: none;
}

.mobile-sidebar.offcanvas .offcanvas-body {
  padding: 0;
}

.mobile-sidebar.offcanvas .offcanvas-body ul li a {
  display: flex;
  align-items: center;
  gap: 25px;
  color: #9D9D9D;
  font-size: 20px;
  font-weight: 400;
  padding: 20px 40px;
}

.mobile-sidebar.offcanvas .offcanvas-body ul li a.active {
  background: linear-gradient(90deg, transparent 0.01%, rgba(1, 146, 237, 0.3) 70.5%, rgba(1, 146, 237, 0.5) 99.99%);
  color: #0192ED;
}

.mobile-sidebar.offcanvas .offcanvas-body ul li a.active svg {
  fill: #0192ED;
  stroke: none;
}

.mobile-sidebar.offcanvas .offcanvas-body ul li a svg {
  width: 30px;
  height: 30px;
  stroke: #9D9D9D;
  fill: none;
}

@media (max-width: 1600px) {
  .company-journey .journey-details:first-child {
    padding-top: 20%;
  }
}

@media (max-width: 1399px) {
  .journey-animation>div {
    min-width: calc(100% + 200px);
  }

  .journey-animation>div svg {
    margin: -90px 0 -22px -57px;
  }

  .contact-section .contact-form {
    width: 600px;
  }

  .contact-section .contact-form h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .contact-section .contact-form p {
    margin-bottom: 12px;
  }

  .contact-section .contact-form .form-control,
  .contact-section .contact-form .form-select {
    margin-bottom: 12px;
  }

  .company-journey .journey-details:first-child {
    padding-top: 15%;
  }

  .company-journey .journey-details .journey-year {
    font-size: 45px;
  }

  .company-journey .journey-details .journey-content {
    font-size: 25px;
    width: 40%;
  }

  .about-vasundhara .vasundhara-content h1 {
    font-size: 55px;
  }

  .about-vasundhara .vasundhara-content p {
    font-size: 18px;
  }

  .home-slider .slider-img .slider-content h1 {
    font-size: 48px;
  }

  .home-slider .slider-img .slider-content p {
    font-size: 18px;
  }

  .service-slider .slider-img .slider-content h1 {
    font-size: 48px;
  }

  .service-slider .slider-img .slider-content p {
    font-size: 18px;
    width: 55%;
  }

  .about-us .globe-container #threeGlobe {
    margin: -145px 0 -28px 0;
  }

  .about-us .about-content h5 {
    font-size: 32px;
  }

  .about-us .about-content h5 span {
    font-size: 32px;
  }

  .why-us .why-us-img {
    margin-left: 15px;
  }

  .why-us .why-us-list {
    margin-bottom: 20px;
    width: 100%;
  }

  .why-us .why-us-list:first-child,
  .why-us .why-us-list:last-child {
    margin-left: -23%;
  }

  .why-us .why-us-list:nth-child(2),
  .why-us .why-us-list:nth-child(6) {
    margin-left: -11%;
  }

  .why-us .why-us-list:nth-child(3),
  .why-us .why-us-list:nth-child(5) {
    margin-left: -2%;
  }

  .why-us .why-us-list h5 {
    font-size: 20px;
  }

  .why-us .why-us-list p {
    font-size: 16px;
  }

  .process-section .process-steps {
    width: auto;
    background-size: contain;
  }

  .process-section .process-steps .step-one {
    padding-left: 33%;
  }

  .process-section .process-steps .step-three {
    padding-left: 46%;
    padding-top: 13%;
    width: 98%;
  }

  .process-section .process-steps .step-five {
    padding-left: 43%;
  }

  .process-section .process-steps h4 {
    font-size: 30px;
  }

  .process-section .process-steps h3 {
    font-size: 32px;
  }

  .our-services h3 {
    font-size: 50px;
  }

  .our-services p:not(.mb-0) {
    font-size: 18px;
  }

  .our-services ul li {
    font-size: 18px;
  }

  .why-we h2 {
    font-size: 32px;
  }

  .why-we p {
    padding-bottom: 10px;
    margin-bottom: 18px;
  }

  .mini-banner .slick-slider .slick-list {
    min-height: 165px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide span {
    font-size: 30px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active span {
    font-size: 40px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active.slick-current span {
    font-size: 30px;
  }
}

@media (max-width: 1199px) {
  .journey-animation>div {
    min-width: calc(100% + 170px);
  }

  .journey-animation>div svg {
    margin: -80px 0 -18px -50px;
  }

  .contact-section .contact-form {
    width: 500px;
    padding: 20px 30px;
  }

  .contact-section .contact-form h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .contact-section .contact-form p {
    margin-bottom: 10px;
    margin-top: 0;
  }

  .contact-section .contact-form .btn-primary {
    padding: 10px 20px;
  }

  .contact-section .contact-form .form-control,
  .contact-section .contact-form .form-select {
    margin-bottom: 8px;
    padding: 7px 10px;
  }

  .company-journey.section-py {
    padding-bottom: 320px;
  }

  .company-journey .journey-details {
    gap: 150px;
  }

  .company-journey .journey-details:nth-child(3) {
    padding-top: 35%;
  }

  .company-journey .journey-details:nth-child(2),
  .company-journey .journey-details:nth-child(5),
  .company-journey .journey-details:nth-child(6),
  .company-journey .journey-details:last-child {
    padding-top: 26%;
  }

  .company-journey .journey-details .journey-year {
    font-size: 35px;
  }

  .company-journey .journey-details .journey-content {
    font-size: 20px;
  }

  .about-vasundhara .vasundhara-content {
    padding-block: 217px;
  }

  .about-vasundhara .vasundhara-content h1 {
    font-size: 50px;
    width: 35%;
  }

  .about-vasundhara .vasundhara-content p {
    font-size: 17px;
  }

  .home-slider .slider-img .slider-content {
    padding-block: 180px;
  }

  .home-slider .slider-img .slider-content h1 {
    font-size: 40px;
  }

  .home-slider .slider-img .slider-content p {
    font-size: 17px;
  }

  .service-slider .slider-img .slider-content {
    padding-block: 200px;
  }

  .service-slider .slider-img .slider-content h1 {
    font-size: 40px;
  }

  .service-slider .slider-img .slider-content p {
    font-size: 17px;
    width: 62%;
  }

  .service-section .service-list {
    padding: 40px 30px;
    gap: 35px;
  }

  .service-section .service-list h3 {
    font-size: 200px;
  }

  .service-section .service-list h4 {
    font-size: 30px;
  }

  .service-section .service-list p {
    font-size: 18px;
    line-height: 35px;
  }

  .section-py {
    padding-block: 80px;
  }

  .section-heading {
    margin-bottom: 40px;
  }

  .section-heading img {
    width: 220px;
  }

  .section-heading h2 {
    font-size: 38px;
  }

  .section-heading p {
    font-size: 17px;
    margin-top: 30px;
  }

  .logo-wrapper {
    width: 200px;
  }

  .btn-primary {
    padding: 12px 35px;
  }

  .btn-outline {
    padding: 8px 16px;
  }

  .header-wrapper .header-content .right-header ul {
    padding-right: 30px;
  }

  .header-wrapper .header-content .right-header ul li {
    padding-right: 40px;
  }

  .our-portfolio .nav-tabs {
    gap: 15px;
  }

  .our-portfolio .portfolio-tab {
    margin-top: 20px;
  }

  .our-portfolio .portfolio-tab .nav-tabs {
    gap: 15px;
    justify-content: center;
  }

  .our-portfolio .portfolio-tab .tab-content h3 {
    font-size: 28px;
    top: 15px;
  }

  .why-us .why-us-img {
    margin-left: 10px;
  }

  .why-us .why-us-list:first-child,
  .why-us .why-us-list:last-child {
    margin-left: -34%;
  }

  .why-us .why-us-list:nth-child(2),
  .why-us .why-us-list:nth-child(6) {
    margin-left: -16%;
  }

  .why-us .why-us-list:nth-child(3),
  .why-us .why-us-list:nth-child(5) {
    margin-left: -3%;
  }

  .why-us .why-us-list h5 {
    font-size: 18px;
  }

  .why-us .why-us-list p {
    font-size: 14px;
    margin-top: 2px;
  }

  .process-section .process-steps {
    margin-top: 10px;
  }

  .process-section .process-steps .step-one {
    margin-top: -10px;
  }

  .process-section .process-steps .step-three {
    width: 100%;
    padding-top: 12%;
  }

  .process-section .process-steps .step-four,
  .process-section .process-steps .step-six {
    padding-top: 11%;
  }

  .process-section .process-steps h4 {
    font-size: 25px;
  }

  .process-section .process-steps h3 {
    font-size: 28px;
  }

  .process-section .process-steps p {
    font-size: 17px;
    line-height: 35px;
  }

  .footer-wrapper {
    padding-bottom: 50px;
  }

  .about-us .globe-container {
    background-size: contain;
  }

  .about-us .globe-container #threeGlobe {
    height: 500px;
    margin: -105px 0 -28px 0;
  }

  .about-us p {
    font-size: 16px;
  }

  .about-us h2 {
    font-size: 32px;
    margin-bottom: 30px;
  }

  .about-us .about-content {
    gap: 12px;
  }

  .about-us .about-content img {
    width: 65px;
  }

  .about-us .about-content h5 {
    font-size: 28px;
    margin-bottom: 4px;
  }

  .about-us .about-content h5 span {
    font-size: 28px;
    margin-bottom: 4px;
  }

  .about-us .about-content span {
    font-size: 15px;
  }

  .why-we .badge {
    font-size: 12px;
    padding: 6px 12px;
  }

  .why-we h2 {
    font-size: 26px;
    margin-top: 6px;
    margin-bottom: 4px;
  }

  .why-we p {
    font-size: 14px;
    padding-bottom: 6px;
    margin-bottom: 10px;
  }

  .why-we .why-we-content img {
    width: 40px;
  }

  .why-we .why-we-content h6 {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 2px;
  }

  .why-we .why-we-content p {
    font-size: 13px;
  }

  .blog-list .blog-card h5 {
    font-size: 17px;
  }

  .client-review p {
    font-size: 22px;
  }

  .client-review h6 {
    font-size: 20px;
  }

  .client-review .client-img img {
    width: 320px;
  }

  .client-review .client-img .img-effect {
    width: 318px;
    height: 378px;
  }

  .client-review .slick-arrow {
    top: 82%;
  }

  .mini-banner .slick-slider .slick-list {
    min-height: 135px;
  }

  .mini-banner .slick-slider .slick-list .slick-track {
    top: -10px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide span {
    font-size: 25px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active span {
    font-size: 35px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active.slick-current span {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .journey-animation>div {
    min-width: 100%;
  }

  .journey-animation>div svg {
    margin: -192px 0 -160px 15px;
  }

  .contact-section .contact-form {
    margin: 50px auto;
    box-shadow: 0px 0px 25px 5px rgba(1, 146, 237, 0.1);
  }

  .company-journey {
    position: relative;
  }

  .company-journey::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .company-journey .journey-details {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
  }

  .company-journey .journey-details:first-child,
  .company-journey .journey-details:nth-child(2),
  .company-journey .journey-details:nth-child(3),
  .company-journey .journey-details:nth-child(4),
  .company-journey .journey-details:nth-child(5),
  .company-journey .journey-details:nth-child(6),
  .company-journey .journey-details:last-child {
    padding-top: 0;
  }

  .company-journey .journey-details:last-child {
    margin-bottom: 0;
  }

  .company-journey .journey-details .journey-year {
    font-size: 30px;
    order: -1;
    padding: 6px 30px;
  }

  .company-journey .journey-details .journey-content {
    font-size: 18px;
    width: 100%;
    padding: 20px;
  }

  .about-vasundhara {
    background-position-x: 70%;
  }

  .about-vasundhara .vasundhara-content h1 {
    font-size: 45px;
    width: 40%;
  }

  .about-vasundhara .vasundhara-content p {
    font-size: 16px;
    width: 50%;
  }

  .home-slider .slider-img {
    background-position-x: 62%;
  }

  .home-slider .slider-img .slider-content h1 {
    font-size: 38px;
    width: 47%;
  }

  .home-slider .slider-img .slider-content p {
    font-size: 16px;
    width: 64%;
  }

  .service-slider .slider-img {
    background-position-x: 62%;
  }

  .service-slider .slider-img .slider-content h1 {
    font-size: 38px;
    width: 52%;
  }

  .service-slider .slider-img .slider-content p {
    font-size: 16px;
    width: 64%;
  }

  .service-section .service-list {
    padding: 30px 20px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
  }

  .service-section .service-list h3 {
    font-size: 180px;
    position: absolute;
  }

  .service-section .service-list h4 {
    font-size: 25px;
    position: relative;
  }

  .service-section .service-list p {
    font-size: 17px;
    line-height: 30px;
    position: relative;
  }

  .our-portfolio .nav-tabs {
    gap: 10px;
  }

  .our-portfolio .nav-tabs .nav-item .nav-link {
    font-size: 16px;
    padding: 8px 18px;
    background-color: rgba(1, 146, 237, 0.04);
  }

  .our-portfolio .portfolio-tab .tab-content h3 {
    font-size: 25px;
    top: 17px;
  }

  .our-portfolio .portfolio-tab .nav-tabs .nav-item .nav-link {
    background-color: transparent;
  }

  .our-portfolio .portfolio-tab .nav-tabs .nav-item .nav-link.active {
    font-size: 14px;
    background-color: #0192ED;
  }

  .why-us .why-us-img {
    display: none;
  }

  .why-us .why-us-list {
    text-align: center;
  }

  .why-us .why-us-list:first-child,
  .why-us .why-us-list:nth-child(2),
  .why-us .why-us-list:nth-child(3),
  .why-us .why-us-list:nth-child(5),
  .why-us .why-us-list:nth-child(6),
  .why-us .why-us-list:last-child {
    margin-left: 0;
  }

  .why-us .why-us-list p {
    font-size: 16px;
    margin-top: 4px;
  }

  .process-section .process-steps {
    background-image: none;
    margin-top: 0;
  }

  .process-section .process-steps .step-one,
  .process-section .process-steps .step-two,
  .process-section .process-steps .step-three,
  .process-section .process-steps .step-four,
  .process-section .process-steps .step-five,
  .process-section .process-steps .step-six {
    margin-bottom: 20px;
    width: 100%;
    background: linear-gradient(180deg, #252525 -27.38%, transparent 100%);
    padding: 12px 20px;
    border-radius: 14px;
  }

  .process-section .process-steps .step-one {
    margin-top: 0;
  }

  .process-section .process-steps .step-six {
    margin-bottom: 0;
  }

  .process-section .process-steps h3 {
    display: none;
  }

  .process-section .process-steps h4 {
    font-size: 22px;
    text-align: center;
  }

  .process-section .process-steps p {
    font-size: 16px;
    text-align: center;
    color: #6B6B6B;
    line-height: 30px;
  }

  .technologies-section .technologies-list {
    gap: 40px;
  }

  .technologies-section .technologies-list .technologies-name .technologies-img {
    width: 150px;
    height: 150px;
  }

  .technologies-section .technologies-list .technologies-name .technologies-img img {
    width: 75px;
  }

  .section-heading h2 {
    font-size: 35px;
  }

  .section-heading p {
    font-size: 16px;
    margin-top: 25px;
  }

  .section-heading img {
    display: none;
  }

  .header-wrapper .header-content .left-header {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .header-wrapper .header-content .left-header .toggle-bar {
    display: block;
  }

  .header-wrapper .header-content .right-header ul {
    display: none;
  }

  .footer-wrapper {
    background-image: url(../../../public/images/png/res-footer-bg.webp);
    background-position: top;
  }

  .footer-wrapper h3 {
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 25px;
  }

  .footer-wrapper .logo-wrapper {
    margin-bottom: 30px;
  }

  .footer-wrapper .contact-info {
    flex-direction: column;
    margin-top: 50px;
    gap: 4px;
  }

  .footer-wrapper .contact-info h4 {
    font-size: 20px;
  }

  .footer-wrapper .contact-info ul li {
    margin-bottom: 0;
  }

  .footer-wrapper .sub-footer p {
    margin-top: 4px;
  }

  .footer-wrapper .sub-footer .social-icons {
    justify-content: center;
  }

  .about-us .globe-container #threeGlobe {
    margin: -105px 0 0;
  }

  .about-us h2 {
    text-align: left;
    margin-bottom: 25px;
    font-size: 28px;
  }

  .about-us .about-content {
    margin-bottom: 30px;
  }

  .about-us .about-content:last-child {
    margin-bottom: 30px;
  }

  .our-services .services-video {
    background: unset;
    border: none;
    border-radius: 0;
  }

  .our-services ul {
    display: none;
  }

  .our-services h3 {
    margin-inline: auto;
    width: 35%;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
  }

  .our-services p:not(.mb-0) {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .our-services button {
    display: block;
    margin-inline: auto;
  }

  .client-review p {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 20px;
  }

  .client-review h6 {
    font-size: 18px;
  }

  .client-review span {
    font-size: 16px;
  }

  .client-review .client-img {
    order: -1;
    padding-block: 0;
  }

  .client-review .client-img img {
    width: auto;
    filter: unset;
    margin-inline: auto;
  }

  .client-review .client-img .img-effect {
    display: none;
  }

  .client-review .slick-slider {
    padding-bottom: 70px;
  }

  .client-review .slick-slider .slick-slide {
    padding-inline: 10px;
  }

  .client-review .slick-slider .slick-arrow {
    top: unset;
    bottom: -14px;
  }

  .client-review .slick-slider .slick-arrow.slick-prev {
    background-color: #0192ED;
    border: none;
    left: -6px;
  }

  .client-review .slick-slider .slick-arrow.slick-next {
    left: 58px;
  }

  .blog-list .btn-primary {
    margin-top: 50px;
    border-radius: 50px;
    padding: 10px 25px;
  }

  .blog-list .slick-slider .slick-track {
    padding-bottom: 10px;
  }

  .blog-list .slick-slider .slick-track .slick-slide {
    padding-inline: 10px;
  }

  .blog-list .slick-slider .slick-dots li button::before {
    font-size: 12px;
    opacity: 1;
    color: #CDD4D6;
  }

  .blog-list .slick-slider .slick-dots li.slick-active button::before {
    content: "\f111";
    font-family: "Font Awesome 6 Free";
    color: #0192ED;
  }

  .mini-banner h2 {
    font-size: 35px;
  }

  .mini-banner p {
    margin-top: 10px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide span {
    color: #CDD4D6;
    background: none;
    -webkit-text-fill-color: unset;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active span {
    color: #F8A02D;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active.slick-current span {
    color: #CDD4D6;
    background: none;
    -webkit-text-fill-color: unset;
  }

  .custom-cursor {
    display: none;
  }
}

@media (max-width: 767px) {
  .journey-animation>div svg {
    margin: -142px 0 -120px 10px;
  }

  .company-journey.section-py {
    padding-bottom: 260px;
  }

  .company-journey .journey-details {
    gap: 15px;
    margin-bottom: 30px;
  }

  .company-journey .journey-details .journey-year {
    font-size: 25px;
  }

  .company-journey .journey-details .journey-content {
    font-size: 16px;
  }

  .home-slider .slider-img {
    background-position-x: center;
    background-position-y: 30%;
  }

  .home-slider .slider-img .slider-content {
    text-align: center;
    padding-top: 100%;
    padding-bottom: 100px;
  }

  .home-slider .slider-img .slider-content h1 {
    font-size: 34px;
    width: 80%;
    margin-inline: auto;
  }

  .home-slider .slider-img .slider-content p {
    margin: 15px auto;
    width: 75%;
  }

  .home-slider .slider-img .slider-content button {
    margin: auto;
  }

  .home-slider .slider-img .slider-content .slider-pagination {
    right: 0;
    bottom: 30px;
  }

  .home-slider .slider-img .slider-content .slider-pagination h5 {
    font-size: 25px;
  }

  .home-slider .slider-img .slider-content .slider-pagination h5 span {
    font-size: 15px;
  }

  .home-slider .slick-dots {
    bottom: 50px;
  }

  .service-slider .slider-img {
    background-position-x: center;
    background-position-y: 30%;
  }

  .service-slider .slider-img .slider-content {
    text-align: center;
    padding-top: 100%;
    padding-bottom: 100px;
  }

  .service-slider .slider-img .slider-content h1 {
    font-size: 34px;
    width: 80%;
    margin-inline: auto;
  }

  .service-slider .slider-img .slider-content p {
    margin: 15px auto;
    width: 86%;
  }

  .service-slider .slider-img .slider-content button {
    margin: auto;
  }

  .about-vasundhara {
    background-image: url(../../../public/images/png/res-vasundhara-bg.webp);
    background-position-x: center;
    background-position-y: 30%;
  }

  .about-vasundhara .vasundhara-content {
    text-align: center;
    padding-top: 100%;
    padding-bottom: 100px;
  }

  .about-vasundhara .vasundhara-content h1 {
    font-size: 40px;
    width: 50%;
    margin-inline: auto;
  }

  .about-vasundhara .vasundhara-content p {
    margin: 15px auto;
    width: 85%;
  }

  .about-vasundhara .vasundhara-content button {
    margin: auto;
  }

  .our-portfolio .nav-tabs {
    gap: 15px;
  }

  .our-portfolio .portfolio-tab .tab-content h3 {
    font-size: 20px;
  }

  .our-portfolio .portfolio-tab .nav-tabs {
    padding-top: 60px;
  }

  .technologies-section .technologies-list {
    gap: 30px;
  }

  .technologies-section .technologies-list .technologies-name .technologies-img {
    width: 140px;
    height: 140px;
  }

  .technologies-section .technologies-list .technologies-name .technologies-img img {
    width: 65px;
  }

  .section-py {
    padding-block: 60px;
  }

  .section-heading p {
    font-size: 15px;
    margin-top: 20px;
  }

  .section-heading h2 {
    font-size: 30px;
  }

  .btn-primary {
    padding: 10px 20px;
    font-size: 16px;
  }

  .btn-outline {
    padding: 7px 14px;
    font-size: 16px;
  }

  .header-wrapper .header-content .left-header {
    gap: 30px;
  }

  .about-us h2 {
    margin-bottom: 20px;
    font-size: 25px;
  }

  .our-services h3 {
    width: 45%;
  }

  .why-we h2 {
    font-size: 22px;
  }

  .footer-wrapper {
    padding-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .journey-animation>div svg {
    margin: -102px 0 -89px 10px;
  }

  .contact-section .contact-form {
    width: calc(100% - 40px);
    margin: 20px;
    padding: 15px;
  }

  .contact-section .contact-form h2 {
    font-size: 20px;
  }

  .contact-section .contact-form p {
    font-size: 14px;
  }

  .contact-section .contact-form .btn-primary {
    padding: 7px 14px;
  }

  .contact-section .contact-form .form-control,
  .contact-section .contact-form .form-select {
    font-size: 15px;
  }

  .contact-section .contact-form .form-select {
    white-space: normal;
    padding-right: 25px;
  }

  .company-journey.section-py {
    padding-bottom: 230px;
  }

  .company-journey .journey-details {
    gap: 10px;
    margin-bottom: 20px;
  }

  .company-journey .journey-details .journey-year {
    font-size: 20px;
    padding: 6px 20px;
  }

  .company-journey .journey-details .journey-content {
    padding: 15px;
  }

  .home-slider .slider-img .slider-content {
    padding-bottom: 70px;
  }

  .home-slider .slider-img .slider-content h1 {
    font-size: 25px;
    width: 100%;
  }

  .home-slider .slider-img .slider-content p {
    font-size: 14px;
    width: 100%;
  }

  .home-slider .slider-img .slider-content .slider-pagination h5 {
    font-size: 20px;
  }

  .home-slider .slider-img .slider-content .slider-pagination h5 span {
    font-size: 14px;
  }

  .home-slider .slick-dots {
    bottom: 30px;
  }

  .home-slider .slick-dots li {
    width: 20px;
  }

  .home-slider .slick-dots li button::after {
    width: 10px;
    margin-left: 15px;
  }

  .service-slider .slider-img .slider-content {
    padding-bottom: 50px;
  }

  .service-slider .slider-img .slider-content h1 {
    font-size: 25px;
    width: 100%;
  }

  .service-slider .slider-img .slider-content p {
    font-size: 14px;
    width: 100%;
  }

  .about-vasundhara::before {
    display: none;
  }

  .about-vasundhara .vasundhara-content {
    padding-bottom: 50px;
  }

  .about-vasundhara .vasundhara-content h1 {
    font-size: 25px;
    width: 100%;
  }

  .about-vasundhara .vasundhara-content p {
    font-size: 14px;
    width: 100%;
  }

  .our-portfolio .nav-tabs {
    gap: 10px;
  }

  .our-portfolio .nav-tabs .nav-item .nav-link {
    padding: 6px 12px;
    font-size: 15px;
  }

  .our-portfolio .portfolio-tab .tab-content h3 {
    font-size: 17px;
  }

  .our-portfolio .portfolio-tab .nav-tabs {
    gap: 14px;
    padding-top: 54px;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }

  .our-portfolio .portfolio-tab .nav-tabs .nav-item .nav-link {
    padding: 5px 10px;
  }

  .section-heading {
    margin-bottom: 25px;
  }

  .section-heading h2 {
    font-size: 25px;
  }

  .section-heading p {
    font-size: 14px;
    margin-top: 15px;
  }

  .section-py {
    padding-block: 40px;
  }

  .logo-wrapper {
    width: 150px;
  }

  .header-wrapper .header-content {
    padding-block: 12px;
  }

  .header-wrapper .header-content .left-header {
    gap: 15px;
  }

  .header-wrapper .header-content .left-header .toggle-bar {
    width: 27px;
  }

  .footer-wrapper {
    padding-bottom: 30px;
  }

  .footer-wrapper .logo-wrapper {
    margin-bottom: 20px;
  }

  .footer-wrapper h3 {
    font-size: 20px;
  }

  .footer-wrapper ul li {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .footer-wrapper .contact-info {
    margin-top: 20px;
  }

  .footer-wrapper .contact-info h4 {
    font-size: 17px;
  }

  .footer-wrapper .contact-info p {
    font-size: 16px;
  }

  .footer-wrapper .sub-footer {
    margin-top: 40px;
  }

  .footer-wrapper .sub-footer span,
  .footer-wrapper .sub-footer p {
    font-size: 16px;
  }

  .footer-wrapper .sub-footer .social-icons {
    gap: 20px;
  }

  .service-section .service-list {
    padding: 15px;
    margin-bottom: 15px;
  }

  .service-section .service-list h4 {
    font-size: 20px;
  }

  .service-section .service-list p {
    font-size: 16px;
    line-height: 25px;
  }

  .why-us .why-us-list {
    padding: 12px;
  }

  .process-section .process-steps h4 {
    font-size: 20px;
  }

  .technologies-section .technologies-list {
    gap: 20px;
    flex-direction: column;
  }

  .technologies-section .technologies-list .technologies-name {
    display: flex;
    align-items: center;
    gap: 80px;
  }

  .technologies-section .technologies-list .technologies-name h5 {
    display: block;
  }

  .technologies-section .technologies-list .technologies-name .technologies-img {
    width: 100px;
    height: 100px;
  }

  .technologies-section .technologies-list .technologies-name .technologies-img img {
    width: 50px;
  }

  .about-us .globe-container {
    position: relative;
  }

  .about-us .globe-container #threeGlobe {
    height: 400px;
    margin: -80px 0 55px;
  }

  .about-us .globe-container .client-slider {
    position: absolute;
    bottom: -55px;
    width: 100%;
    padding-block: 50px;
  }

  .about-us h2 {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .about-us p {
    font-size: 14px;
  }

  .about-us .about-content {
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }

  .about-us .about-content:last-child {
    margin-bottom: 20px;
  }

  .about-us .about-content h5 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .about-us .about-content h5 span {
    font-size: 20px;
    margin-bottom: 0;
  }

  .our-services h3 {
    font-size: 30px;
    width: 100%;
  }

  .our-services .services-video .play-btn {
    width: 60px;
    height: 60px;
  }

  .our-services .services-video .play-btn svg {
    font-size: 20px;
  }

  .why-we h2 {
    font-size: 18px;
  }

  .client-review p {
    font-size: 17px;
    margin-bottom: 25px;
  }

  .client-review h6 {
    font-size: 16px;
  }

  .client-review span {
    font-size: 15px;
  }

  .client-review .slick-slider {
    padding-bottom: 50px;
  }

  .client-review .slick-slider .slick-arrow {
    width: 35px;
    height: 35px;
    bottom: -12px;
  }

  .client-review .slick-slider .slick-arrow::before {
    font-size: 16px;
  }

  .client-review .slick-slider .slick-arrow.slick-prev {
    left: -2px;
  }

  .client-review .slick-slider .slick-arrow.slick-next {
    left: 46px;
  }

  .mini-banner h2 {
    font-size: 30px;
  }
}

@media (max-width: 420px) {
  .journey-animation>div svg {
    margin: -75px 0 -65px 7px;
  }

  .logo-wrapper {
    width: 120px;
  }

  .btn-primary {
    padding: 4px 8px;
    font-size: 14px;
  }

  .btn-outline {
    padding: 6px 12px;
    font-size: 14px;
  }

  .header-wrapper .header-content .left-header .toggle-bar {
    width: 22px;
  }

  .mobile-sidebar.offcanvas .offcanvas-header {
    padding: 30px;
  }

  .mobile-sidebar.offcanvas .offcanvas-body ul li a {
    padding: 20px 30px;
    gap: 20px;
  }

  .technologies-section .technologies-list .technologies-name {
    gap: 40px;
  }

  .about-us .globe-container #threeGlobe {
    height: 300px;
    margin: -60px 0 40px;
  }

  .about-us .globe-container .client-slider {
    gap: 15px;
  }

  .about-us .globe-container .client-slider .client-detail {
    gap: 15px;
  }

  .about-us .globe-container .client-slider .client-detail img {
    width: 100px;
  }

  .about-us .about-content span {
    font-size: 14px;
  }

  .our-services h3 {
    font-size: 25px;
  }

  .our-services p:not(.mb-0) {
    font-size: 14px;
  }

  .our-services .services-video .play-btn {
    width: 50px;
    height: 50px;
  }

  .our-services .services-video .play-btn svg {
    font-size: 18px;
  }

  .mini-banner .slick-slider .slick-list {
    min-height: unset;
  }

  .mini-banner .slick-slider .slick-list .slick-track {
    top: -20px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide span {
    font-size: 17px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active span {
    font-size: 25px;
  }

  .mini-banner .slick-slider .slick-list .slick-track .slick-slide.slick-active.slick-current span {
    font-size: 17px;
  }
}

